import {PermissionCheckerService} from 'abp-ng2-module';
import {AppSessionService} from '@shared/common/session/app-session.service';

import {Injectable} from '@angular/core';
import {AppMenu} from './app-menu';
import {AppMenuItem} from './app-menu-item';

@Injectable()
export class AppNavigationService {
    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService
    ) {}

    getMenu(): AppMenu {
        var hasCustomerId = !!this._appSessionService.user.customerId;
        var hasRestrictionId = !!this._appSessionService.user.restrictionProjectId;

        return new AppMenu('MainMenu', 'MainMenu', [
            new AppMenuItem('Dashboard', 'Pages.Dashboard', 'fa-solid fa-chart-line-up', '/app/main/dashboard'),
            new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', 'fa-solid fa-chart-line-up', '/app/main/dashboard'),
            new AppMenuItem('Dashboard', 'Pages.Administration.Host.Dashboard', 'fa-solid fa-chart-line-up', '/app/admin/hostDashboard'),
            new AppMenuItem('Orders', 'Pages.Orders', 'fa-solid fa-rectangle-history', '/app/main/shop/orders'),
            new AppMenuItem(hasCustomerId ? 'OwnProjects' : 'Projects', 'Pages.Projects', 'fa-solid fa-address-card', hasRestrictionId ? '/' : '/app/main/creatorProject/projects'),
            hasCustomerId ? 
                new AppMenuItem('CoreData', 'Pages.Customers', 'fa-solid fa-buildings', '/app/main/shop/customers/createOrEdit', undefined, undefined, undefined, {'id': this._appSessionService.user.customerId }) : 
                new AppMenuItem('Customers', 'Pages.Customers', 'fa-solid fa-buildings', '/app/main/shop/customers'),
            new AppMenuItem('Shop', '', 'fa-solid fa-bag-shopping', '', [], [
                new AppMenuItem('Items', 'Pages.Administration.Items', 'fa-solid fa-tags', '/app/admin/shop/items'),
                new AppMenuItem('Countries', 'Pages.Countries', 'fa-solid fa-flag', '/app/main/shop/countries'),
                new AppMenuItem('ShippingProviders', 'Pages.ShippingProviders', 'fa-solid fa-truck-fast', '/app/main/shop/shippingProviders'),
            ]),
            new AppMenuItem('Administration', '', 'fa-solid fa-folder-gear', '', [], [
                new AppMenuItem('Roles', 'Pages.Administration.Roles', 'fa-solid fa-user-group', '/app/admin/roles'),
                new AppMenuItem('Users', 'Pages.Administration.Users', 'fa-solid fa-user', '/app/admin/users'),
            
                new AppMenuItem('Tenants', 'Pages.Tenants', 'fa-solid fa-building-user', '/app/admin/tenants'),
                new AppMenuItem('Settings', 'Pages.Administration.Host.Settings', 'fa-solid fa-gear', '/app/admin/hostSettings'),
                new AppMenuItem('Settings', 'Pages.Administration.Tenant.Settings', 'fa-solid fa-gear', '/app/admin/tenantSettings')
            ]),
            new AppMenuItem('Operation', '', 'fa-solid fa-sliders', '', [], [
                new AppMenuItem('Editions', 'Pages.Editions', 'fa-solid fa-cubes-stacked', '/app/admin/editions'),
                new AppMenuItem('OrganizationUnits', 'Pages.Administration.OrganizationUnits', 'fa-solid fa-paragraph', '/app/admin/organization-units'),
                new AppMenuItem('Languages', 'Pages.Administration.Languages', 'fa-solid fa-language', '/app/admin/languages', ['/app/admin/languages/{name}/texts']),
                new AppMenuItem('AuditLogs', 'Pages.Administration.AuditLogs', 'fa-solid fa-folder-open', '/app/admin/auditLogs'),
                new AppMenuItem('Maintenance', 'Pages.Administration.Host.Maintenance', 'fa-solid fa-lock-keyhole', '/app/admin/maintenance'),
                new AppMenuItem('Subscription', 'Pages.Administration.Tenant.SubscriptionManagement', 'fa-solid fa-rotate', '/app/admin/subscription-management'),
                new AppMenuItem('WebhookSubscriptions', 'Pages.Administration.WebhookSubscription', 'fa-solid fa-globe', '/app/admin/webhook-subscriptions'),
                new AppMenuItem('DynamicProperties', 'Pages.Administration.DynamicProperties', 'fa-solid fa-list-check', '/app/admin/dynamic-property'),
            ]),
        ]);
    }

    checkChildMenuItemPermission(menuItem): boolean {
        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null) {
                if (subMenuItem.route) {
                    return true;
                }
            } else if (this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            }

            if (subMenuItem.items && subMenuItem.items.length) {
                let isAnyChildItemActive = this.checkChildMenuItemPermission(subMenuItem);
                if (isAnyChildItemActive) {
                    return true;
                }
            }
        }
        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (
            menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' &&
            this._appSessionService.tenant &&
            !this._appSessionService.tenant.edition
        ) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
            if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }

    /**
     * Returns all menu items recursively
     */
    getAllMenuItems(): AppMenuItem[] {
        let menu = this.getMenu();
        let allMenuItems: AppMenuItem[] = [];
        menu.items.forEach((menuItem) => {
            allMenuItems = allMenuItems.concat(this.getAllMenuItemsRecursive(menuItem));
        });

        return allMenuItems;
    }

    private getAllMenuItemsRecursive(menuItem: AppMenuItem): AppMenuItem[] {
        if (!menuItem.items) {
            return [menuItem];
        }

        let menuItems = [menuItem];
        menuItem.items.forEach((subMenu) => {
            menuItems = menuItems.concat(this.getAllMenuItemsRecursive(subMenu));
        });

        return menuItems;
    }
}
